import styled from "@emotion/styled"
import { breakpoints, colors, dimensions } from "../styles"
import { Link } from "gatsby"

export const MainWrapper = styled.div`
  max-width: ${dimensions.contentWidth};
  margin: auto;
  padding: 20px 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  @media (max-width: ${breakpoints.tablet}px) {
    justify-content: center;
  }
`

export const ProductContainer = styled.div`
  width: 270px;
  position: relative;
  margin: 15px;
  background: white;
  border-radius: 10px;
  padding: 35px 20px;
  padding-bottom: 25px;
  text-align: right;
  opacity: ${props => props.hoverAction ? "0.3" : "1"};
  transition-duration: 0.4s;
  cursor: pointer;
  @media (max-width: ${breakpoints.tablet}px) {
    
  }
`

export const ProductImage = styled.img`
  max-height: 150px;
`

export const ProductCollection = styled.span`
  font-size: 1.1rem;
  font-weight: 500;
  text-transform: uppercase;
  color: #3b3b3b;
`

export const ProductType = styled.span`
  font-size: 0.9rem;
  font-weight: 400;
  color: #949494;
`

export const WrapperWishList = styled.button`
  position: absolute;
  top: 15px;
  left: 12px;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
`
