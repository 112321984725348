import styled from "@emotion/styled"
import { breakpoints, colors, dimensions } from "../styles"
import { Link } from "gatsby"

export const MainWrapper = styled.div`
  margin: auto;
  @media (max-width: ${breakpoints.tablet}px) {}
`

export const HeadingWrapper = styled.div`
  padding: 12px 0;
  width: 100%;
  text-align: center;
`

export const HeadingTitle = styled.h1`
  font-size: 1.6rem;
  font-weight: 500;
`

export const HeadingDivider = styled.div`
  width: 100%;
  height: 1px;
  background: #e4e4e4;
`

export const ProductNotFound = styled.div`
  width: 100%;
  text-align: center;
  margin: 2rem auto;
  @media (max-width: ${breakpoints.tablet}px) {}
`

export const LinkNotFound = styled.a`
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: ${colors.redWaskoll};
  }
`
