import React, { useEffect, useState } from "react"
import { isLoggedIn } from "../../utils/auth"
import {
  addItemWishListCookie,
  createProductLink,
  getWishListCookie,
  removeItemWishListCookie
} from "../../utils/productHelpers"
import {
  MainWrapper,
  ProductCollection,
  ProductContainer,
  ProductImage,
  ProductType,
  WrapperWishList
} from "./StyledCategoryProductGrid"
import { colors } from "../styles"

import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai'
import { navigate } from "gatsby-link"



export default ({ productList }) => {
  const [wishList, setWishList] = useState([])
  const [productHovered, setProductHovered] = useState(false)

  useEffect(() => {
    if (!isLoggedIn()) {
      const currentCookie = getWishListCookie()
      if (currentCookie) {
        setWishList(currentCookie)
        console.info("CURRENT COOKIE", currentCookie)
      }
    }
  }, [])

  const handleHoverProduct = (e, product) => {
    e.preventDefault()
    setProductHovered(product)
  }

  const handlerAddToWishList = (e, product) => {
    e.preventDefault()
    addItemWishListCookie(product)
    setWishList([...wishList, product])
  }

  const handlerRemoveToWishList = (e, product) => {
    e.preventDefault()
    removeItemWishListCookie(product)
    setWishList(wishList.filter(item => item !== product))
  }

  const handleViewItem = (e, product) => {
    e.preventDefault()
    let productUrl = createProductLink(product)
    navigate(`/${productUrl}`)
  }

  return (
    <MainWrapper>
      {productList.map(product => (
        <ProductContainer onClick={(e) => handleViewItem(e, product)}
          hoverAction={productHovered && productHovered !== product.id}
          onMouseEnter={e => handleHoverProduct(e, product.id)}
          onMouseLeave={e => handleHoverProduct(e, false)}
          key={product.id}
        >
          {!wishList.find(itemCookie => itemCookie === product.id) ? (
            <WrapperWishList onClick={(e) => handlerAddToWishList(e, product.id)}>
              <AiOutlineHeart size={18} color={colors.darkGrey}/>
            </WrapperWishList>
          ) : (
            <WrapperWishList onClick={(e) => handlerRemoveToWishList(e, product.id)}>
              <AiFillHeart size={18} color={colors.redWaskoll}/>
            </WrapperWishList>
          )}
          <ProductImage src={process.env.IMAGE_ENDPOINT + product.images[0].id + "_MEDIUM.png"}/>
          <ProductCollection>{product.collection}</ProductCollection>
          <br/>
          <ProductType>{product.attributeMetal} {product.attributeGem}</ProductType>
        </ProductContainer>
      ))}
    </MainWrapper>
  )
}
