import React, { useEffect, useState } from "react"
import {
  MainWrapper,
  MobileWrapper,
  FormSelect,
  FormSelectWrapper,
  DesktopWrapper,
  SelectMobile
} from "./StyledCategoryFilterBlock"

export default ({ listFilter, setFilter }) => {
  const handleChange = (e, name, value) => {
    e.preventDefault()
    setFilter(name, value)
  }

  return (
    <MainWrapper>
      <DesktopWrapper>
        {listFilter.length > 0 && listFilter.map((itemFilter, index) => (
          <FormSelectWrapper key={itemFilter.name + index}>
            <FormSelect
              value={itemFilter.selected}
              onChange={e => handleChange(e, itemFilter.name, e.target.value)}>

              <option value={itemFilter.name} selected disabled hidden>{itemFilter.title}</option>

              {itemFilter.data.map((itemList, index) => (
                <option key={itemList + index} value={itemList}>{itemList}</option>
              ))}
            </FormSelect>
          </FormSelectWrapper>
        ))}
      </DesktopWrapper>
      <MobileWrapper>
        {listFilter.length > 0 && listFilter.map((itemFilter, index) => (
          <div key={itemFilter.name + index}>
            <SelectMobile
              width={itemFilter.selected ? itemFilter.selected.length : itemFilter.title.length}
              defaultValue={itemFilter.name}
              value={itemFilter.selected}
              onChange={e => handleChange(e, itemFilter.name, e.target.value)}>

              <option value={itemFilter.name} disabled>{itemFilter.title}</option>

              {itemFilter.data.map((itemList, index) => (
                <option key={itemList + index} value={itemList}>{itemList}</option>
              ))}
            </SelectMobile>
          </div>
        ))}
      </MobileWrapper>

    </MainWrapper>
  )
}
