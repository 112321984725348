import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import FilterBlock from "../CategoryFilterBlock"
import ProductGrid from "../CategoryProductGrid"
import { MainWrapper, HeadingTitle, HeadingWrapper, HeadingDivider, ProductNotFound, LinkNotFound } from "./StyledCategoryBlockFiltered"
import { allFilterList } from "../shared/ProductMetadata"

export default ({ pageContext}) => {
  const [listProduct, setProductList] = useState(pageContext.listProducts)
  const [productListFiltered, setProductListFiltered] = useState(listProduct)
  const [listFilter, setListFilter] = useState(pageContext.listFilter)

  const handleSetFilter = (name, value) => {
    setListFilter(listFilter.map(itemList => {
      if (itemList.name === name) itemList.selected = value
      return itemList;
    }))
  }

  useEffect(() => {
    let allFilter = listFilter.filter(item => item.selected)
    if (allFilter.length > 0) {
      let listFiltered = listProduct.filter(itemList =>
        allFilter.every(itemFilter => itemList[itemFilter.name] === itemFilter.selected)
      )
      setProductListFiltered(listFiltered)
    }
  }, [listFilter])

  return (

    <MainWrapper>
      <HeadingWrapper>
        <HeadingTitle>
          {pageContext.filterName}
        </HeadingTitle>
      </HeadingWrapper>
      <HeadingDivider/>
      <FilterBlock listFilter={listFilter} setFilter={handleSetFilter}/>
      <HeadingDivider/>
      {productListFiltered.length > 0 ? (
        <ProductGrid productList={productListFiltered}/>
      ) : (
        <ProductNotFound>
          Il n'y a pas de bijoux pour cette sélection. Souhaitez-vous un <LinkNotFound>bijoux sur mesure?</LinkNotFound>
        </ProductNotFound>
      )}

    </MainWrapper>
  )
}
