import styled from "@emotion/styled"
import { breakpoints, colors, dimensions } from "../styles"
import { Link } from "gatsby"

export const MainWrapper = styled.div`
  padding: 20px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const DesktopWrapper = styled.div`
  @media (max-width: ${breakpoints.tablet}px) {
    display: none;
  }
`

export const MobileWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 0 1rem;
  justify-content: space-around;
  @media (min-width: ${breakpoints.tablet}px) {
    display: none;
  }
`

export const SelectMobile = styled.select`
  padding: .2rem .3rem;
  background: none;
  &:after {
    content: '▾';
    color: ${colors.redWaskoll};
    background: white;
    right: 2px;
    top: 5px;
    padding: 5px 10px;
    position: absolute;
    pointer-events: none;
  };
`

export const Label = styled.p`
  font-size: 0.9rem;
  margin-bottom: 8px;
  color: #383838;
  margin-right: 20px;
`

export const FormSelectWrapper = styled.div`
  position: relative;
  margin: 0 10px;
  &:after {
    content: '▾';
    color: ${colors.redWaskoll};
    background: white;
    right: 2px;
    top: 5px;
    padding: 5px 10px;
    position: absolute;
    pointer-events: none;
  };
`

export const FormSelect = styled.select`
  display: flex;
  font-family: Lato;
  font-size: 1rem;
  color: #383838;
  outline: none;
  flex-direction: column;
  border-radius: 5px;
  padding: 10px 0.5em;
  text-transform: capitalize;
  cursor: pointer;
  line-height: 1.1;
  border: 1px solid rgb(56 56 56 / 0.08);
  background-color: #fff;
  box-shadow: 0 0 5px 0px #0000000d;
  background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
  &:focus {
    box-shadow: inset 0 0 2px rgba(67, 90, 111, 0.14), inset 0 0 0 1px #ffbcc7, 0 0 0 3px #ff577324;
  };
  &.select-error {
    box-shadow: inset 0 0 2px rgba(67, 90, 111, 0.14), inset 0 0 0 1px #ec4c47a3, 0 0 0 3px #ec4c4714;
  }
`
